
.banner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
    background-image: url("../../assets/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    justify-content: right;
}
/* .profile{
    width: 20%;
    background-color: rgb(96, 201, 236);
    font-size: 1rem;
}

.profile-image {
    border-radius: 20px;
    width: 80%;
} */
