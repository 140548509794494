.about-container {
    display: flex;
    flex-direction: row;
    justify-items: left;
    justify-content: left;
    background-color: #ffffff;
    border-radius: 30px;
    
}
.social-buttons {
    display: flex;
    justify-content: left;
}
.about-text-box {
    margin: 0px 10px 0px 0px;
}
.about-container p{
    font-size: 18px;
    text-align: left;
}

.about-container h1{
    font-size: 30px;
    text-align: left;
}

.profile-image {
    margin: 20px 20px 20px 20px;
    border-radius: 100px;
    width: 23%;
}

