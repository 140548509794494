.skills-container {
    display: grid;
    justify-items: left;
    justify-content: center;
    font-size: 30px;
    margin: 50px 0px 50px 0px;
}

.skills-container h1 {
    display: flex;
    border-bottom: 5px solid black;
    padding-bottom: 10px;
    border-bottom-color: black;
    width: 100%;
    margin-bottom: 0px;
    
}
.language-buttons {
    display: flex;
    flex-direction: row;
    
}