.projects-container {
    display: grid;
    justify-items: center;
    justify-content: normal;
    background-color: #ffffff;
    border-radius: 30px;
    margin: 3rem 0rem 0rem 0rem;
    padding: 0rem 0rem 1rem 0rem;

}

.project-table {
    display: flex;
    margin: 0px 0px 0px 0px;
    flex-wrap: wrap;
    justify-content: center;
}

.project {
    display:flex;
    margin: 0px 10px 1.5rem 0px;
    align-items: flex-start;
}

.project-box {
    display: grid;
    grid-template-rows: 30px;
    width: 400px;
    justify-items: start;
    align-items: center;
    text-align: left;
}

.header-box{
    display:grid;
    grid-template-columns: 1fr 0.5fr 0.5fr;
    width: inherit;
    align-items: baseline;
}

.header-box button{
    margin: 0px 10px 0px 10px;
    width: 100px;
    justify-self: right;
    
}

.project-icon {
    width: 262px;
    height: 150px;
    margin: 10px;
}

.project h4 {
    margin: 10px 0px 0px 0px;
    font-size: 15px;
}
.project p{
    margin: 10px 0px 0px 0px;
}